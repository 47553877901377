<template>
    <VueMultiselect
        class="w-full border border-solid rounded-xl transition ease-in-out vueselect"
        :class="{
            'border-box-stroke': !error,
            'border-red-600': error,
        }"
        v-model="valueSync"
        v-bind="$attrs"
        v-on="$listeners"
        multiple
        @focus.native="$emit('focus', $event)"
        :placeholder="getPlaceHolder"
        :options="getOptions"
        label="option_label"
        track-by="id"
        role=""
        :disabled="disabled || formAlreadySubmitted"
    />
</template>

<script>
import VueMultiselect from "@/components/vue-multiselect";

const EventsEnum = Object.freeze({
    VALUE_UPDATE: "input",
});
export default {
    components: {
        VueMultiselect,
    },
    props: {
        fieldData: {
            type: Object,
            require: true,
        },
        error: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        formAlreadySubmitted: {
            type: Boolean,
        }
    },
    computed: {
        getOptions() {
            return this.fieldData.options || [];
        },
        valueSync: {
            get() {
                return this.fieldData.value;
            },
            set(val) {
                this.$emit(EventsEnum.VALUE_UPDATE, val);
                this.$emit("handleFieldValueChnage", val);
            },
        },
        getPlaceHolder(){
            return this.fieldData.placeholder || ''
        }
    },
};
</script>

<style lang="scss" scoped>
[role="combobox"] {
    width: 70%;
}
::v-deep {
    .multiselect {
        @apply text-base;
    }
    .multiselect__tags {
        min-height: unset;
        @apply pr-10 pl-3 py-1.5 text-base;
        border: none;
        &:focus-within {
           box-shadow: 0 0 0 1.5px rgba(37, 99, 235, var(--tw-border-opacity)) !important;
       }
    }
    .multiselect__placeholder {
        margin-bottom: 0;
    }
    
}
.invalid {
    ::v-deep {
        .multiselect__tags {
            @apply border-red-600;
        }
    }
}

.vueselect{
    height: 50px;

}
</style>